import {
  FormHelperText,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormLabel from '@material-ui/core/FormLabel';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import DayPicker, { DateUtils } from 'react-day-picker';
import { List } from '../../../../components';
import { DAYS } from '../../../../constants';

const DEFAULT_FORM_DATA = {
  address: '',
  allowedLocations: [],
  city: '',
  customDates: [],
  customDisabledDates: [],
  dailyBookingsAvailable: 0,
  goldCost: 0,
  id: '',
  intraTrueLimit: 0,
  latitude: '',
  longitude: '',
  name: '',
  openDays: {
    fri: false,
    mon: false,
    sat: false,
    sun: false,
    thu: false,
    tue: false,
    wed: false,
  },
  ownerEmail: '',
  ownerName: '',
  pageUrl: '',
  phone: '',
  platinumCost: 0,
  silverCost: 0,
};

const useStyles = makeStyles(theme => ({
  divider: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },

  drawer: {
    padding: theme.spacing(3),
    width: '75%',
  },

  root: {
    padding: theme.spacing(3),
  },
}));

const LocationForm = props => {
  const {
    buttonText,
    className,
    formErrors,
    isOpen,
    location,
    locations,
    onSubmit,
    onToggle,
    selectLocation,
    ...rest
  } = props;

  const classes = useStyles();

  const [formData, setFormData] = useState({
    ...DEFAULT_FORM_DATA,
    openDays: { ...DEFAULT_FORM_DATA.openDays },
  });

  const locationsExceptThis = useMemo(() => {
    if (!location) {
      return null;
    }

    return locations.filter(loc => loc.id !== location.id);
  }, [location, locations]);

  useEffect(() => {
    if (location) {
      if (!location.customDates) {
        location.customDates = [];
      }

      location.customDates = location.customDates.map(date => new Date(date));

      if (!location.customDisabledDates) {
        location.customDisabledDates = [];
      }

      location.customDisabledDates = location.customDisabledDates.map(date => new Date(date));

      setFormData(location);
    }
  }, [location]);

  const toggleDrawer = open => event => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    if (!open) {
      setFormData({
        ...DEFAULT_FORM_DATA,
        openDays: { ...DEFAULT_FORM_DATA.openDays },
      });
    }

    selectLocation(null);
    onToggle(open);
  };

  const handleChange = useCallback(
    event => {
      setFormData({
        ...formData,
        allowedLocations: [...formData.allowedLocations],
        openDays: { ...formData.openDays },
        [event.target.name]: event.target.value,
      });
    },
    [formData],
  );

  const onOpenDaysChange = useCallback(
    event => {
      setFormData({
        ...formData,
        allowedLocations: [...formData.allowedLocations],
        openDays: {
          ...formData.openDays,
          [event.target.name]: event.target.checked,
        },
      });
    },
    [formData],
  );

  const onCustomDatesChange = useCallback(
    (day, { selected }) => {
      const date = new Date(
        Date.UTC(day.getFullYear(), day.getMonth(), day.getDate()),
      );
      const selectedDays = formData.customDates.concat();

      if (selected) {
        const selectedIndex = selectedDays.findIndex(selectedDay => {
          return DateUtils.isSameDay(selectedDay, date);
        });

        selectedDays.splice(selectedIndex, 1);
      } else {
        selectedDays.push(date);
      }

      setFormData({
        ...formData,
        allowedLocations: [...formData.allowedLocations],
        customDates: selectedDays,
        customDisabledDates: [...formData.customDisabledDates],
        openDays: { ...formData.openDays },
      });
    },
    [formData],
  );

  const onCustomDisabledDatesChange = useCallback(
    (day, { selected }) => {
      const date = new Date(
        Date.UTC(day.getFullYear(), day.getMonth(), day.getDate()),
      );
      const selectedDays = formData.customDisabledDates.concat();

      if (selected) {
        const selectedIndex = selectedDays.findIndex(selectedDay => {
          return DateUtils.isSameDay(selectedDay, date);
        });

        selectedDays.splice(selectedIndex, 1);
      } else {
        selectedDays.push(date);
      }

      setFormData({
        ...formData,
        allowedLocations: [...formData.allowedLocations],
        customDates: [...formData.customDates],
        customDisabledDates: selectedDays,
        openDays: { ...formData.openDays },
      });
    },
    [formData],
  );

  const onAllowedLocationsChange = useCallback(
    id => () => {
      const currentIndex = formData.allowedLocations.indexOf(id);
      const newChecked = [...formData.allowedLocations];

      if (currentIndex === -1) {
        newChecked.push(id);
      } else {
        newChecked.splice(currentIndex, 1);
      }

      setFormData({
        ...formData,
        allowedLocations: newChecked,
        openDays: {
          ...formData.openDays,
        },
      });
    },
    [formData],
  );

  return (
    <Drawer
      {...rest}
      anchor="right"
      classes={{
        paper: classes.drawer,
        root: classes.root,
      }}
      className={className}
      open={isOpen}
      onClose={toggleDrawer(false)}
    >
      <form autoComplete="off" noValidate>
        <Typography variant="h3" gutterBottom>
          Add Location
        </Typography>

        <Divider className={classes.divider} />

        <Grid container spacing={3}>
          <Grid
            alignContent="flex-start"
            container
            item
            md={4}
            spacing={3}
            xs={12}
          >
            <Grid item md={12} xs={12}>
              <Typography variant="h5">Location Details</Typography>
            </Grid>

            <Grid item md={12} xs={12}>
              <TextField
                error={!!formErrors.name}
                fullWidth
                helperText={formErrors.name}
                label="Name"
                margin="dense"
                name="name"
                onChange={handleChange}
                required
                value={formData.name}
                variant="outlined"
              />
            </Grid>

            <Grid item md={12} xs={12}>
              <TextField
                error={!!formErrors.address}
                fullWidth
                helperText={formErrors.address}
                label="Address"
                margin="dense"
                name="address"
                onChange={handleChange}
                required
                value={formData.address}
                variant="outlined"
              />
            </Grid>

            <Grid item md={12} xs={12}>
              <TextField
                error={!!formErrors.ownerName}
                fullWidth
                helperText={formErrors.ownerName}
                label="Owner Name"
                margin="dense"
                name="ownerName"
                onChange={handleChange}
                required
                value={formData.ownerName}
                variant="outlined"
              />
            </Grid>

            <Grid item md={12} xs={12}>
              <TextField
                error={!!formErrors.ownerEmail}
                fullWidth
                helperText={formErrors.ownerEmail}
                label="Owner Email"
                margin="dense"
                name="ownerEmail"
                onChange={handleChange}
                required
                value={formData.ownerEmail}
                variant="outlined"
              />
            </Grid>

            <Grid item md={6} xs={12}>
              <TextField
                error={!!formErrors.phone}
                fullWidth
                helperText={formErrors.phone}
                label="Phone Number"
                margin="dense"
                name="phone"
                onChange={handleChange}
                required
                value={formData.phone}
                variant="outlined"
              />
            </Grid>

            <Grid item md={6} xs={12}>
              <TextField
                error={!!formErrors.city}
                fullWidth
                helperText={formErrors.city}
                label="City"
                margin="dense"
                name="city"
                onChange={handleChange}
                required
                value={formData.city}
                variant="outlined"
              />
            </Grid>
          </Grid>

          <Grid
            alignContent="flex-start"
            container
            item
            md={4}
            spacing={3}
            xs={12}
          >
            <Grid item md={12} xs={12}>
              <Typography variant="h5">Cost and Others</Typography>
            </Grid>

            <Grid item md={6} xs={12}>
              <TextField
                error={!!formErrors.latitude}
                fullWidth
                helperText={formErrors.latitude}
                label="Latitude"
                margin="dense"
                name="latitude"
                onChange={handleChange}
                required
                type="number"
                value={formData.latitude}
                variant="outlined"
              />
            </Grid>

            <Grid item md={6} xs={12}>
              <TextField
                error={!!formErrors.longitude}
                fullWidth
                helperText={formErrors.longitude}
                label="Longitude"
                margin="dense"
                name="longitude"
                onChange={handleChange}
                required
                type="number"
                value={formData.longitude}
                variant="outlined"
              />
            </Grid>

            <Grid item md={12} xs={12}>
              <TextField
                error={!!formErrors.pageUrl}
                fullWidth
                helperText={formErrors.pageUrl}
                label="Page URL"
                margin="dense"
                name="pageUrl"
                onChange={handleChange}
                required
                value={formData.pageUrl}
                variant="outlined"
              />
            </Grid>

            <Grid item md={6} xs={12}>
              <TextField
                error={!!formErrors.dailyBookingsAvailable}
                fullWidth
                helperText={formErrors.dailyBookingsAvailable}
                label="Daily Bookings"
                margin="dense"
                name="dailyBookingsAvailable"
                onChange={handleChange}
                required
                type="number"
                value={formData.dailyBookingsAvailable}
                variant="outlined"
              />
            </Grid>

            <Grid item md={6} xs={12}>
              <TextField
                error={!!formErrors.intraTrueLimit}
                fullWidth
                helperText={formErrors.intraTrueLimit}
                label="intraTrue Limit"
                margin="dense"
                name="intraTrueLimit"
                onChange={handleChange}
                required
                type="number"
                value={formData.intraTrueLimit}
                variant="outlined"
              />
            </Grid>

            <Grid item md={6} xs={12}>
              <TextField
                error={!!formErrors.silverCost}
                fullWidth
                helperText={formErrors.silverCost}
                label="Silver Cost"
                margin="dense"
                name="silverCost"
                onChange={handleChange}
                required
                type="number"
                value={formData.silverCost}
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">€</InputAdornment>
                  ),
                }}
              />
            </Grid>

            <Grid item md={6} xs={12}>
              <TextField
                error={!!formErrors.goldCost}
                fullWidth
                helperText={formErrors.goldCost}
                label="Gold Cost"
                margin="dense"
                name="goldCost"
                onChange={handleChange}
                required
                type="number"
                value={formData.goldCost}
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">€</InputAdornment>
                  ),
                }}
              />
            </Grid>

            <Grid item md={6} xs={12}>
              <TextField
                error={!!formErrors.platinumCost}
                fullWidth
                helperText={formErrors.platinumCost}
                label="Platinum Cost"
                margin="dense"
                name="platinumCost"
                onChange={handleChange}
                required
                type="number"
                value={formData.platinumCost}
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">€</InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>

          <Grid
            alignContent="flex-start"
            container
            item
            md={4}
            spacing={3}
            xs={12}
          >
            <Grid item md={12} xs={12}>
              <Typography variant="h5">Show Locations</Typography>
            </Grid>

            <Grid item md={12} xs={12}>
              <List
                items={locationsExceptThis || locations}
                onChange={onAllowedLocationsChange}
                selected={formData.allowedLocations}
              />
            </Grid>
          </Grid>

          <Grid
            alignContent="flex-start"
            container
            item
            md={12}
            spacing={3}
            xs={12}
          >
            <Grid item md={12} xs={12}>
              <Typography variant="h5">Availability</Typography>
            </Grid>

            <Grid item md={12} xs={12}>
              <FormControl component="fieldset" error={!!formErrors.openDays}>
                <FormLabel component="legend">Open Days</FormLabel>
                <FormGroup row>
                  {DAYS.map(day => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formData.openDays[day.code]}
                          onChange={onOpenDaysChange}
                        />
                      }
                      key={day.code}
                      label={day.label}
                      name={day.code}
                    />
                  ))}
                </FormGroup>
                <FormHelperText>{formErrors.openDays}</FormHelperText>
              </FormControl>
            </Grid>

            <Grid item md={12} xs={12}>
              <FormControl
                component="fieldset"
                error={!!formErrors.customDates}
              >
                <FormLabel component="legend">Custom Dates</FormLabel>
                <FormGroup row>
                  <DayPicker
                    selectedDays={formData.customDates}
                    onDayClick={onCustomDatesChange}
                    disabledDays={[
                      {
                        before: new Date(),
                      },
                    ]}
                  />
                </FormGroup>
                <FormHelperText>{formErrors.customDates}</FormHelperText>
              </FormControl>
            </Grid>

            <Grid item md={12} xs={12}>
              <FormControl
                component="fieldset"
                error={!!formErrors.customDisabledDates}
              >
                <FormLabel component="legend">Disabled Dates for INTRA=true</FormLabel>
                <FormGroup row>
                  <DayPicker
                    selectedDays={formData.customDisabledDates}
                    onDayClick={onCustomDisabledDatesChange}
                    disabledDays={[
                      {
                        before: new Date(),
                      },
                    ]}
                  />
                </FormGroup>
                <FormHelperText>{formErrors.customDisabledDates}</FormHelperText>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>

        <Divider className={classes.divider} />

        <Button
          onClick={() => onSubmit(formData)}
          color="primary"
          variant="contained"
        >
          {buttonText}
        </Button>
      </form>
    </Drawer>
  );
};

LocationForm.propTypes = {
  buttonText: PropTypes.string,
  className: PropTypes.string,
  formErrors: PropTypes.shape(),
  isOpen: PropTypes.bool.isRequired,
  location: PropTypes.shape(),
  locations: PropTypes.array,
  onSubmit: PropTypes.func.isRequired,
  onToggle: PropTypes.func.isRequired,
  selectLocation: PropTypes.func.isRequired,
};

LocationForm.defaultProps = {
  buttonText: 'Submit',
  className: '',
  formErrors: {},
  location: null,
  locations: [],
};

export default LocationForm;
